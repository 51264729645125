<template>
  <div>
    <Header></Header>
    <div>
      <img class="lbt" :src="baseURL+solution.mainDrawingSrc" alt="" />
    </div>
    <div class="body">
      <div>
        <div class="cpszh">{{ solution.title }}分类</div>
        <div class="bodyTitle">
          <div class="product">{{ solution.eTitle }}</div>
<!--          <div class="more">-->
<!--            <div class="gdal">更多服务</div>-->
<!--            <div>></div>-->
<!--          </div>-->
        </div>
      </div>
      <div class="bodyTopContent">
        <div>
          <div class="jjfaFirA1" v-if="classList.length>0">
            <div class="jjfaFirA1M"></div>
            <img class="jjfa1" :src="baseURL+classList[0].fileSrc" alt="" />
            <div class="jjfaFirA1Font1">{{classList[0].title}}</div>
            <div class="jjfaFirA1Font2">
              {{classList[0].introduce}}
            </div>
            <!-- <div class="jjfaFirA1Font3">了解更多</div> -->
          </div>
          <div class="jjfaFirA1" v-if="classList.length>3">
            <div class="jjfaFirA2M"></div>
            <img class="jjfa2" :src="baseURL+classList[3].fileSrc" alt="" />
            <div class="jjfaFirA1Font1">{{classList[3].title}}</div>
            <div class="jjfaFirA1Font2">
              {{classList[3].introduce}}
            </div>
            <!-- <div class="jjfaFirA1Font3">了解更多</div> -->
          </div>
        </div>
        <div>
          <div class="jjfaFirA2" v-if="classList.length>1">
            <div class="jjfaFirA3M"></div>
            <img class="jjfa3" :src="baseURL+classList[1].fileSrc" alt="" />
            <div class="jjfaFirA2Font1">{{classList[1].title}}</div>
            <div class="jjfaFirA2Font2">
              {{classList[1].introduce}}
            </div>
            <!-- <div class="jjfaFirA2Font3">了解更多</div> -->
          </div>
          <div class="jjfaFirA2" v-if="classList.length>2">
            <div class="jjfaFirA4M"></div>
            <img class="jjfa4" :src="baseURL+classList[2].fileSrc" alt="" />
            <div class="jjfaFirA2Font1">{{classList[2].title}}</div>
            <div class="jjfaFirA2Font2">
              {{classList[2].introduce}}
            </div>
            <!-- <div class="jjfaFirA2Font3">了解更多</div> -->
          </div>
        </div>
      </div>
      <div class="bodyBottomContent">
        <div class="jjfaSecA1" v-if="classList.length>4">
          <div class="jjfaFirA5M"></div>
          <img class="jjfa5" :src="baseURL+classList[4].fileSrc" alt="" />
          <div class="jjfaFirA1Font1">{{classList[4].title}}</div>
          <div class="jjfaSecA1Font2">
            {{classList[4].introduce}}
          </div>
          <!-- <div class="jjfaSecA1Font3">了解更多</div> -->
        </div>
        <div class="jjfaSecA2" v-if="classList.length>5">
          <div class="jjfaFirA6M"></div>
          <img class="jjfa6" :src="baseURL+classList[5].fileSrc" alt="" />
          <div class="jjfaFirA2Font1">{{classList[5].title}}</div>
          <div class="jjfaSecA2Font2">
            {{classList[5].introduce}}
          </div>
          <!-- <div class="jjfaSecA2Font3">了解更多</div> -->
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/views/header.vue";
import Footer from "@/views/footer.vue";
import {getSolution,getSolutionClassificationPage} from "@/api/open";
export default {
  components: {
    Header,
    Footer,
  },
  watch: {
    '$route' (to, from) { //监听URL地址栏参数变化
      if (this.$route.query.solutionId) {
        this.solutionId = this.$route.query.solutionId
        this.getById()
        this.getClassList()
      }
    }
  },
  data() {
    return {
      solutionId:null,
      solution:{},
      classList:[],
      baseURL: process.env.VUE_APP_BASE_API,
    };
  },
  created() {
    this.solutionId = this.$route.query.solutionId
    this.getById()
    this.getClassList()
  },
  methods: {
    getById(){
      getSolution({id:this.solutionId}).then(res=>{
        this.solution = res.data
      })
    },
    getClassList(){
      getSolutionClassificationPage({solutionId:this.solutionId}).then(res=>{
        this.classList = res.rows
      })
    }
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.lbt {
  width: 100%;
}
.body {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 80px;
  background: #fff !important;
}
.cpszh {
  font-size: 24px;
  padding: 40px 0 4px 32px;
  color: #183f85;
  letter-spacing: 1px;
}
.bodyTitle {
  display: flex;
  justify-content: space-between;
  margin-right: 32px;
}
.product {
  font-size: 24px;
  color: #bfbfbf;
  margin-left: 32px;
  letter-spacing: 1px;
}
.more {
  display: flex;
}
.gdal {
  margin-right: 4px;
  font-size: 14px;
  color: #7d7d7d;
}
.bodyTopContent {
  margin-top: 24px;
  display: flex;
}
.jjfa1 {
  width: 567px;
  height: 785px;
  margin: 0 24px 24px 32px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  position: absolute;
  top: 0;
}
.jjfa2 {
  width: 567px;
  height: 255px;
  margin: 0 24px 24px 32px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  position: absolute;
  top: 0;
}
.jjfa3 {
  width: 546px;
  height: 380px;
  margin: 0 0 24px 0;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  position: absolute;
  top: 0;
}
.jjfa4 {
  width: 545px;
  height: 659px;
  margin: 0 0 24px 0;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  position: absolute;
  top: 0;
}
.bodyBottomContent {
  display: flex;
  margin-top: 0;
}
.jjfa5 {
  width: 762px;
  height: 500px;
  margin: 0 24px 0 32px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  position: absolute;
  top: 0;
}
.jjfa6 {
  width: 350px;
  height: 500px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  position: absolute;
  top: 0;
}
.jjfaFirA1 {
  position: relative;
  transition: all 0.5s;
}
.jjfaFirA1:hover{
  transform: scale(1.05);
}
.jjfaFirA1Font1 {
  position: absolute;
  top: 24px;
  left: 56px;
  font-size: 24px;
  color: #ffffff;
  line-height: 30px;
  letter-spacing: 1px;
  z-index: 2;
}
.jjfaFirA1Font2 {
  position: absolute;
  bottom: 32px;
  left: 56px;
  width: 527px;
  color: #ffffff;
  line-height: 30px;
  letter-spacing: 1px;
  z-index: 2;
}
.jjfaFirA1Font3 {
  position: absolute;
  bottom: 40px;
  right: 40px;
  color: #ffffff;
  line-height: 17px;
  z-index: 2;
  cursor: pointer;
}
.jjfaFirA2 {
  position: relative;
  transition: all 0.5s;
}
.jjfaFirA2:hover{
  transform: scale(1.05);
}
.jjfaFirA2Font1 {
  position: absolute;
  top: 24px;
  left: 24px;
  font-size: 24px;
  color: #ffffff;
  line-height: 30px;
  letter-spacing: 1px;
  z-index: 2;
}
.jjfaFirA2Font2 {
  position: absolute;
  bottom: 32px;
  left: 24px;
  width: 495px;
  color: #ffffff;
  line-height: 30px;
  letter-spacing: 1px;
  z-index: 2;
}
.jjfaFirA2Font3 {
  position: absolute;
  bottom: 40px;
  right: 16px;
  color: #ffffff;
  line-height: 17px;
  z-index: 2;
  cursor: pointer;
}
/* .jjfaSecA1 {
  width: 762px;
  height: 500px;
  background: linear-gradient(
    180deg,
    #000000 0%,
    rgba(250, 250, 250, 0) 49%,
    #000000 100%
  );
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  border-radius: 20px 0px 20px 0px;
  margin: 0 24px 0 32px;
} */
.jjfaSecA1 {
  position: relative;
  transition: all 0.5s;
}
.jjfaSecA1:hover{
  transform: scale(1.05);
}
.jjfaSecA1Font2 {
  position: absolute;
  bottom: 33px;
  left: 56px;
  width: 673px;
  color: #ffffff;
  line-height: 30px;
  letter-spacing: 1px;
  z-index: 2;
}
.jjfaSecA1Font3 {
  position: absolute;
  bottom: 16px;
  right: 40px;
  color: #ffffff;
  line-height: 17px;
  z-index: 2;
  cursor: pointer;
}
.jjfaSecA2 {
  position: relative;
  transition: all 0.5s;
}
.jjfaSecA2:hover{
  transform: scale(1.05);
}
.jjfaSecA2Font2 {
  position: absolute;
  bottom: 33px;
  left: 24px;
  width: 309px;
  color: #ffffff;
  line-height: 30px;
  letter-spacing: 1px;
  z-index: 2;
}
.jjfaSecA2Font3 {
  position: absolute;
  bottom: 16px;
  right: 16px;
  color: #ffffff;
  line-height: 17px;
  z-index: 2;
  cursor: pointer;
}
.jjfaFirA1M {
  width: 567px;
  height: 785px;
  margin: 0 24px 24px 32px;
  background: linear-gradient(
    180deg,
    #000000 -150%,
    rgba(250, 250, 250, 0) 50%,
    #000000 150%
  );
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  border-radius: 20px 0px 20px 0px;
  position: relative;
  z-index: 1;
}
.jjfaFirA2M {
  width: 567px;
  height: 255px;
  margin: 0 24px 24px 32px;
  background: linear-gradient(
    180deg,
    #000000 -150%,
    rgba(250, 250, 250, 0) 50%,
    #000000 150%
  );
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  border-radius: 20px 0px 20px 0px;
  position: relative;
  z-index: 1;
}
.jjfaFirA3M {
  width: 546px;
  height: 380px;
  margin: 0 0 24px 0;
  background: linear-gradient(
    180deg,
    #000000 -150%,
    rgba(250, 250, 250, 0) 50%,
    #000000 150%
  );
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  border-radius: 20px 0px 20px 0px;
  position: relative;
  z-index: 1;
}
.jjfaFirA4M {
  width: 545px;
  height: 659px;
  margin: 0 0 24px 0;
  background: linear-gradient(
    180deg,
    #000000 -150%,
    rgba(250, 250, 250, 0) 50%,
    #000000 150%
  );
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  border-radius: 20px 0px 20px 0px;
  position: relative;
  z-index: 1;
}
.jjfaFirA5M {
  width: 762px;
  height: 500px;
  margin: 0 24px 0 32px;
  background: linear-gradient(
    180deg,
    #000000 -150%,
    rgba(250, 250, 250, 0) 50%,
    #000000 150%
  );
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  border-radius: 20px 0px 20px 0px;
  position: relative;
  z-index: 1;
}
.jjfaFirA6M {
  width: 350px;
  height: 500px;
  background: linear-gradient(
    180deg,
    #000000 -150%,
    rgba(250, 250, 250, 0) 50%,
    #000000 150%
  );
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  border-radius: 20px 0px 20px 0px;
  /* margin: 0 0 24px 0; */
  position: relative;
  z-index: 1;
}
</style>
